import React, {FC, useEffect, useState} from "react";
import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import {
    Box,
    Button, CircularProgress, Grid,
    IconButton,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import { AlertDeleteDialogSlide } from "../../components/dialog/alert.delete.dialog.slide";
import { useNavigate } from "react-router-dom";
import { SearchInputField } from "../../components/input/search.input";
import { Alert, MessageState } from "../../components/alert";
import { useStore } from '../../stores/root.store';

const UserPagePure: FC = () => {
    const navigate = useNavigate();
    const { userStore } = useStore();
    const { users } = userStore;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });

    useEffect(() => {
        try {
            setIsLoading(true);
            (async () => {
                await userStore.init()
            })()
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        } finally {
            setIsLoading(false);
        }

    }, [userStore]);

    const handleChangePage = async (event: unknown, newPage: number) => {
        try {
            userStore.meta.currentPage = newPage;
            await userStore.fetchUsers();
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            userStore.meta.perPage = parseInt(event.target.value, 10);
            userStore.meta.currentPage = 0;
            await userStore.fetchUsers();
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    };

    const onHandleDelete = async (id: number) => {
        try {
            await userStore.deleteUser(id);
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    }

    const onHandleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            userStore.userSearch = e.target.value;
            await userStore.fetchUsers();
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    }

    if (isLoading) {
        return (<CircularProgress />)
    }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{
                p: 2,
                height: 540,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom >Users</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '5px 0'
                }}>
                    <SearchInputField onChange={onHandleSearch} />
                    <Button variant='contained' onClick={() => navigate('create')}>
                        Create user
                    </Button>
                </Box>
                <TableContainer>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Data Created</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length
                                ? users.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.createdAt}</TableCell>
                                        <TableCell style={{ width: '5%' }}>
                                            <IconButton
                                                size='small'
                                                onClick={() => navigate(`${user.id}`)}
                                            >
                                                <EditIcon fontSize='small' color='warning' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ width: '5%' }}>
                                            <AlertDeleteDialogSlide
                                                size='small'
                                                iconColor='error'
                                                idx={user.id}
                                                deleteHandler={onHandleDelete}
                                                context={user.name ?? user.email} />
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell align='center' colSpan={5} >
                                        <Typography component='h5' variant='h5' color='blueviolet' gutterBottom >Nothing found</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 35]}
                    component="div"
                    count={userStore.meta.total}
                    page={userStore.meta.currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={userStore.meta.perPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export const UserPage = compose<{}, {}>(observer)(UserPagePure)
