import React, { FC } from "react";
import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import {
    Box, Button,
    Grid, InputLabel,
    Paper, TextField,
    Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { UserApiNS } from "../../../services/user/user.api.type";
import { useStore } from '../../../stores/root.store';
import { BreadcrumbsComponent } from '../../../components/breadcrumbs/breadcrumbs';

const UserCreatePagePure: FC = () => {
    const navigate = useNavigate();
    const { userStore } = useStore();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(100),
            email: Yup
                .string()
                .max(100)
                .required('Email is required'),
            password: Yup
                .string()
                .max(100)
                .required('Password is required')
        }),
        onSubmit: async ({ name, email, password }, helpers) => {
            const body: UserApiNS.UserCreateDTO = {
                name,
                email,
                password
            }

            try {
                await userStore.createUser(body);
                navigate('/user')
            } catch (e: any) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: e })
                helpers.setSubmitting(false)
            }
        }
    })

    return (
        <Grid item xs={10} md={8} lg={6}>
            <BreadcrumbsComponent breadcrumbs={[
                {
                    name: 'User',
                    link: '/user'
                },
                {
                    name: 'new',
                    link: '/consumer/create'
                }
            ]} />
            <Paper sx={{
                p: 2,
                marginTop: 5,
                height: 540,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom > Create New Admin User</Typography>
                <Box sx={{ padding: 5 }}>
                    <form
                        noValidate
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Name
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    error={!!(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Email
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Password
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained" type="submit" >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Grid>
    )
}

export const UserCreatePage = compose<{}, {}>(observer)(UserCreatePagePure)