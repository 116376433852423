import { UserModel } from "./user.model";
import { UserService } from "../../services/user/user.service";
import { makeAutoObservable } from "mobx";
import { UserApiNS } from "../../services/user/user.api.type";
import { PaginationModel } from '../consumer/pagination.model';

export class UserStore {
    meta: PaginationModel = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 10,
        next: null,
        prev: null
    }
    users: UserModel[] = []

    userSearch: string = '';
    isLoading: boolean = false;
    error: string | null = null;

    constructor(
        private userService: UserService = new UserService()
    ) {
        makeAutoObservable(this)
    }

    public set search(search: string) {
        this.userSearch = search
    }

    *init() {
        yield this.fetchUsers()
    }

    public async fetchUserById(id: string) {
        return this.userService.getUserById(id);
    }

    public async updateUser(id: string, body: UserApiNS.UserUpdateDTO) {
        await this.userService.updateUser(id, body);
    }

    public async createUser(data: UserApiNS.UserCreateDTO) {
        const user = await this.userService.createUser(data);
        this.users.push(user);
    }

    public async deleteUser(id: number) {
        await this.userService.deleteUser(id);
        this.users = this.users.filter(user => user.id !== id);
    }

    public async fetchUsers() {
        try {
            this.isLoading = true;
            const response = await this.userService.getUsers({
                search: this.userSearch,
                page: this.meta.currentPage,
                perPage: this.meta.perPage,
            })

            this.users = response.data;
            this.meta = response.meta;
        } catch (e) {
            const typedError = e as any as Error
            this.error = typedError?.message
        } finally {
            this.isLoading = false;
        }
    }
}

const userStore = new UserStore();
export default userStore;
