import React, {ChangeEvent, FC, useEffect, useState} from "react";
import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import {
    Avatar,
    Box,
    Button, CircularProgress,
    Grid,
    InputLabel,
    Paper, Snackbar, TextField,
    Typography,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ColorPickerInput } from "../../../components/input/color-picker";
import { EditorComponent } from "../../../components/editor/editor";
import { DesignStore } from "../../../stores/tenant/design.store";
import { WritingStore } from "../../../stores/tenant/writing.store";
import { TenantApiNS } from "../../../services/tenant/tenant.api.type";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneShowcase } from "../../../components/showcase/phone.showcase";
import { Alert, MessageState } from "../../../components/alert";
import { useStore } from '../../../stores/root.store';
import { BreadcrumbsComponent } from '../../../components/breadcrumbs/breadcrumbs';

const TenantUpdatePagePure: FC = () => {
    const { tenantStore } = useStore();
    const { id } = useParams<"id">();
    const [designStore] = useState(() => new DesignStore());
    const [writingStore] = useState(() => new WritingStore());
    const [name, setName] = useState('');
    const [scope, setScope] = useState('');
    const [owner, setOwner] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [logo, setLogo] = useState<string>('');
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });
    const navigate = useNavigate();
    const [tab, setTab] = useState('1')

    const handleTabClick = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data: TenantApiNS.UpdateTenantDTO = {
            name,
            owner,
            scope,
            design_backgroundColor: designStore.design_backgroundColor,
            design_headerColor: designStore.design_headerColor,
            design_textColor: designStore.design_textColor,
            writing_bodyText: writingStore.writing_bodyText
        }

        if (designStore.design_logo) {
            data.design_logo = designStore.design_logo;
        }

        if (id) {
            try {
                setIsLoading(true);
                tenantStore.updateTenant(id, data);
                navigate('/tenant');
            } catch (e) {
                const err = e as any as Error;
                setError({
                    open: true,
                    severity: 'error',
                    message: err?.message
                })
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            tenantStore.fetchTenantById(id)
                .then(
                    ({ name, owner, scope, design, writing}) => {
                        setName(name);
                        setScope(scope ?? '');
                        setOwner(owner ?? '');

                        if (design?.backgroundColor) {
                            designStore.design_backgroundColor = design.backgroundColor;
                        }

                        if (design?.headerColor) {
                            designStore.design_headerColor = design.headerColor;
                        }

                        if (design?.textColor) {
                            designStore.design_textColor = design.textColor;
                        }

                        if (writing?.bodyText) {
                            writingStore.writing_bodyText = writing.bodyText;
                        }

                        if (design?.logoBase64) {
                            setLogo(design.logoBase64);
                        }
                    })
                .catch((e) => {
                    const error = e as any as Error;
                    setError({
                        open: true,
                        severity: 'error',
                        message: error?.message
                    });
                })
                .finally(() => setIsLoading(false))
        }
    }, [tenantStore, designStore, writingStore, id])

    const handleFileAttach = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target?.files;
        if (files && files.length) {
            designStore.design_logo = files[0];
        }
    }

    return (
        <React.Fragment>
            <Grid item xs={10} md={8} lg={8}>
                <BreadcrumbsComponent breadcrumbs={[
                    {
                        name: 'Tenants',
                        link: '/tenant'
                    },
                    {
                        name: `${name}`,
                        link: `/tenant/${id}`
                    }
                ]} />
                <Paper sx={{
                    p: 2,
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography component='h2' variant='h5' color='primary' gutterBottom > Update tenant</Typography>
                    <Box sx={{ padding: 5 }}>
                        {isLoading
                            ? <CircularProgress />
                            : <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={2}>
                                        <InputLabel
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                fontWeight: 700
                                            }}
                                        >
                                            Name
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                            required
                                            id="name"
                                            name="name"
                                            label="Name"
                                            value={name}
                                            fullWidth
                                            size="small"
                                            autoComplete="off"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <InputLabel
                                          sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontWeight: 700
                                          }}
                                        >
                                            Owner
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                          required
                                          id="owner"
                                          name="owner"
                                          label="Owner"
                                          value={owner}
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <InputLabel
                                          sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              fontWeight: 700
                                          }}
                                        >
                                            Scope
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                          required
                                          id="scope"
                                          name="scope"
                                          label="Scope"
                                          value={scope}
                                          fullWidth
                                          size="small"
                                          autoComplete="off"
                                          variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12} sm={12}
                                    >
                                        <Box sx={{ width: '100%' }}>
                                            <TabContext value={tab} >
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList aria-label="tenant designes" onChange={handleTabClick}>
                                                        <Tab label="General design" value="1" />
                                                        <Tab label="Context design" value="2" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="1" >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={3} lg={3} >
                                                            <InputLabel
                                                                sx={{
                                                                    display: "flex",
                                                                    fontWeight: 700
                                                                }}
                                                            >
                                                                Logo
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3} lg={3}>
                                                            <Button
                                                                variant="contained"
                                                                component='label'
                                                            >
                                                                Upload Logo
                                                                <input
                                                                    type='file'
                                                                    id='design_logo'
                                                                    name='design_logo'
                                                                    onChange={handleFileAttach}
                                                                    hidden
                                                                />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} lg={6}>
                                                            <Avatar
                                                              src={logo}
                                                              sx={{ width: 56, height: 56 }}
                                                              variant='rounded'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3} lg={3}>
                                                            <InputLabel
                                                                sx={{
                                                                    display: "flex",
                                                                    fontWeight: 700
                                                                }}
                                                            >
                                                                Background color
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={9} lg={9}>
                                                            <ColorPickerInput
                                                                value={designStore.design_backgroundColor}
                                                                onChange={(v) => designStore.design_backgroundColor = v}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3} lg={3}>
                                                            <InputLabel
                                                                sx={{
                                                                    display: "flex",
                                                                    fontWeight: 700
                                                                }}
                                                            >
                                                                Header color
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={9} lg={9}>
                                                            <ColorPickerInput
                                                                value={designStore.design_headerColor}
                                                                onChange={(v) => designStore.design_headerColor = v}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3} lg={3}>
                                                            <InputLabel
                                                                sx={{
                                                                    display: "flex",
                                                                    fontWeight: 700
                                                                }}
                                                            >
                                                                Text color
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={9} lg={9}>
                                                            <ColorPickerInput
                                                                value={designStore.design_textColor}
                                                                onChange={(v) => designStore.design_textColor = v}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value="2">
                                                    <EditorComponent initHtmlText={writingStore.writing_bodyText} onChange={(value) => writingStore.writing_bodyText = value} />
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </form>
                        }
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={10} md={4} lg={4}>
                <PhoneShowcase
                  designStore={designStore}
                  writingStore={writingStore}
                  attachedLogo={logo}
                />
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export const TenantUpdatePage = compose<{}, {}>(observer)(TenantUpdatePagePure)
