import { apiClient } from "../../utils/api-client/api-client";
import authStore from "../../stores/auth/auth.store";
import { AxiosResponse } from "axios";
import { ConsumerCountByStatusModel } from "../../stores/dashboard/consumer-count-by-status.model";
import { DashboardApiNS } from "./dashboard.api.type";

export class DashboardService {
    // just for showcase, in the future it could be much more :)
    async getConsumerCountByStatuses(params: DashboardApiNS.GetConsumerCountByStatusParams) {
        const res = await apiClient.get<
            DashboardApiNS.GetConsumerCountByStatusParams,
            AxiosResponse<DashboardApiNS.GetConsumerCountByStatusResponse>
        >
        (
            '/api/v1/dashboard/consumersByStatuses',
            {
                params,
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        );

        return new ConsumerCountByStatusModel(res.data);
    }
}