import { makeAutoObservable } from "mobx";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { ConsumerCountByStatusModel } from "./consumer-count-by-status.model";
import moment from "moment";

export class DashboardStore {
    consumerCountByStatuses: ConsumerCountByStatusModel = {
        APPROVED: 0,
        PENDING: 0,
        BLOCKED: 0
    }
    constructor(
        private readonly dashboardService: DashboardService = new DashboardService()
    ) {
        makeAutoObservable(this);
    }

    public *init() {
        const from = moment('2023-01-01 00:00:00').startOf('month').toISOString();
        const to = moment().endOf('month').toISOString();

        this.consumerCountByStatuses = yield this.dashboardService.getConsumerCountByStatuses({ from, to });
    }
}

const dashboardStore = new DashboardStore();
export default dashboardStore;