import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import React from "react";
import { PieChart } from "@mui/x-charts";
import { ConsumerCountByStatusModel } from "../../stores/dashboard/consumer-count-by-status.model";

type Props = {
    consumersCountByStatuses: ConsumerCountByStatusModel
}

const ConsumerStatusPiePure = ({ consumersCountByStatuses }: Props) => {
    return (
        <PieChart
            series={[
                {
                    data: Object.entries(consumersCountByStatuses).map(([key, value], idx) => {
                        return {
                            id: idx,
                            value,
                            label: key
                        }
                    }),
                    highlightScope: {
                        faded: 'global',
                        highlighted: 'item'
                    },
                    faded: {
                        innerRadius: 30,
                        additionalRadius: -30
                    },
                    outerRadius: 120
                },
            ]}
            width={400}
            height={300}
        />
    )
}

export const ConsumerStatusPie = compose<Props, Props>(observer)(ConsumerStatusPiePure)