import React from 'react';
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { ColorModeContext } from "./pages/settings/setting.page"
import { store, StoreContext } from './stores/root.store';

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
  );
  const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode,
            },
          }),
      [mode],
  );

  return (
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
              <CssBaseline />
              <StoreContext.Provider value={store}>
                <RouterProvider router={router} />
              </StoreContext.Provider>
          </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;
