import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import {
    Avatar,
    Box,
    Button, CircularProgress,
    FormControl, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select, SelectChangeEvent, Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import { ConsumerApiNS } from "../../../services/consumer/consumer.api.type";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, MessageState } from "../../../components/alert";
import { useStore } from '../../../stores/root.store';
import { BreadcrumbsComponent } from '../../../components/breadcrumbs/breadcrumbs';

const ConsumerUpdatePagePure = () => {
    const { consumerStore, tenantStore } = useStore();
    const { id } = useParams<"id">();
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            auth0Password: '',
            photo: undefined,
            status: ConsumerApiNS.ConsumerStatus.APPROVED,
            url: '',
            birthday: '',
            gender: ConsumerApiNS.Gender.OTHER,
            tenantId: '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(100)
                .optional()
                .nullable()
                .notRequired(),
            surname: Yup
                .string()
                .max(100)
                .nullable()
                .default(null)
                .notRequired(),
            email: Yup
                .string()
                .max(100)
                .required('Email is required'),
            auth0Password: Yup
                .string()
                .max(100)
                .nullable()
                .default(undefined)
                .notRequired(),
            status: Yup
                .string()
                .max(100)
                .required('Status is required'),
            url: Yup
                .string()
                .nullable()
                .default(null)
                .notRequired(),
            birthday: Yup
                .date()
                .optional()
                .default(null)
                .notRequired(),
            gender: Yup
                .string()
                .max(100)
                .required('Gender is required'),
            tenantId: Yup
                .number()
                .required('Tenant is required'),
            photo: Yup
                .mixed()
        }),
        onSubmit: async (values, helpers) => {
            try {
                setIsLoading(true);
                const { name, surname, email, auth0Password, status, url, tenantId, birthday, gender } = values;

                const body: ConsumerApiNS.ConsumerUpdateDTO = {
                    name,
                    surname,
                    email,
                    auth0Password,
                    status,
                    url,
                    birthday,
                    gender,
                    tenantId
                }

                if (avatar) {
                    body.photo = avatar;
                }

                if (id) {
                    await consumerStore.updateConsumer(id, body);
                    await navigate('/consumer')
                }
            } catch (e: any) {
                const err = e as any as Error;
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err?.message })
                helpers.setSubmitting(false)
                setError({
                    open: true,
                    severity: 'error',
                    message: err?.message
                })
            } finally {
                setIsLoading(false);
            }
        }
    });

    const onHandleStatus = (event: SelectChangeEvent) => {
        formik.setFieldValue('status', event.target.value);
    }

    const onHandleGender = (event: SelectChangeEvent) => {
        formik.setFieldValue('gender', event.target.value);
    }

    const onHandleTenant = (event: SelectChangeEvent) => {
        formik.setFieldValue('tenantId', event.target.value);
    }

    useEffect(() => {
        try {
            setIsLoading(true);
            if (id) {
                if (!tenantStore.tenants.length) {
                    tenantStore.fetchTenants()
                }

                consumerStore.fetchConsumerById(id).then(
                    ({ name, surname, email, photo, status, url, birthday, gender, tenant}) => {
                        formik.setFieldValue('name', name);
                        formik.setFieldValue('surname', surname);
                        formik.setFieldValue('email', email);
                        formik.setFieldValue('status', status);
                        formik.setFieldValue('url', url ?? '');
                        formik.setFieldValue('birthday', birthday ?? '');
                        formik.setFieldValue('gender', gender ?? ConsumerApiNS.Gender.OTHER);
                        formik.setFieldValue('tenantId', tenant?.id ?? '');
                        if (photo) formik.setFieldValue('photo', photo);
                    }
                )
            }
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            })
        } finally {
            setIsLoading(false);
        }
    }, [])

    return (
        <Grid item xs={10} md={8} lg={6}>
            <BreadcrumbsComponent breadcrumbs={[
                {
                    name: 'Consumers',
                    link: '/consumer'
                },
                {
                    name: `${formik.values.name ?? formik.values.email}`,
                    link: `/consumer/${id}`
                }
            ]} />
            <Paper sx={{
                p: 2,
                marginTop: 5,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom > Update consumer</Typography>
                <Box sx={{ padding: 5 }}>
                    <form
                        noValidate
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Name
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    error={!!(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} key='surname_label'>
                                <InputLabel
                                  sx={{
                                      display: "flex",
                                      fontWeight: 700
                                  }}
                                >
                                    Surname
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10} key='surname'>
                                <TextField
                                  id="surname"
                                  name="surname"
                                  label="Surname"
                                  error={!!(formik.touched.surname && formik.errors.surname)}
                                  helperText={formik.touched.surname && formik.errors.surname}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.surname}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                  variant="outlined"
                                  disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Email
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Password
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    id="auth0Password"
                                    name="auth0Password"
                                    label="Password"
                                    type="password"
                                    error={!!(formik.touched.auth0Password && formik.errors.auth0Password)}
                                    helperText={formik.touched.auth0Password && formik.errors.auth0Password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.auth0Password}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Photo
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    component='label'
                                >
                                    Upload Logo
                                    <input
                                        type='file'
                                        id='photo'
                                        name='photo'
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            const files = e.target?.files;
                                            if (files && files.length) {
                                                setAvatar(files[0]);
                                            }
                                        }}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Avatar
                                    alt={formik.values.email}
                                    src={formik.values.photo}
                                    sx={{ width: 56, height: 56 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Status
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <FormControl fullWidth>
                                    <InputLabel id="status">Status *</InputLabel>
                                    <Select
                                        required
                                        labelId="status"
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        label="Status *"
                                        error={!!(formik.touched.status && formik.errors.status)}
                                        onBlur={formik.handleBlur}
                                        onChange={onHandleStatus}
                                        disabled={isLoading}
                                    >
                                        {Object.entries(ConsumerApiNS.ConsumerStatus).map(([key, value]) => (
                                            <MenuItem value={key}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} key='url_label'>
                                <InputLabel
                                  sx={{
                                      display: "flex",
                                      fontWeight: 700
                                  }}
                                >
                                    Url
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10} key='url'>
                                <TextField
                                  id="url"
                                  name="url"
                                  label="Url"
                                  error={!!(formik.touched.url && formik.errors.url)}
                                  helperText={formik.touched.url && formik.errors.url}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.url}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                  variant="outlined"
                                  disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} key='gender_label'>
                                <InputLabel
                                  sx={{
                                      display: "flex",
                                      fontWeight: 700
                                  }}
                                >
                                    Gender
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10} key='gender'>
                                <FormControl fullWidth>
                                    <InputLabel id="gender">Gender *</InputLabel>
                                    <Select
                                      required
                                      labelId="gender"
                                      id="gender"
                                      value={formik.values.gender}
                                      label="Gender *"
                                      error={!!(formik.touched.gender && formik.errors.gender)}
                                      onBlur={formik.handleBlur}
                                      onChange={onHandleGender}
                                      disabled={isLoading}
                                    >
                                        {Object.entries(ConsumerApiNS.Gender).map(([key, value]) => (
                                          <MenuItem value={key}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} key='birthday_label'>
                                <InputLabel
                                  sx={{
                                      display: "flex",
                                      fontWeight: 700
                                  }}
                                >
                                    Birthday
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10} key='birthday'>
                                <TextField
                                  id="birthday"
                                  name="birthday"
                                  label=""
                                  type="date"
                                  error={!!(formik.touched.birthday && formik.errors.birthday)}
                                  helperText={formik.touched.birthday && formik.errors.birthday}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.birthday}
                                  fullWidth
                                  size="small"
                                  autoComplete="off"
                                  variant="outlined"
                                  disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Tenant
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <FormControl fullWidth>
                                    <InputLabel id="tenant">Tenant *</InputLabel>
                                    <Select
                                        required
                                        labelId="tenant"
                                        id="tenant"
                                        value={formik.values.tenantId}
                                        label="Tenant *"
                                        error={!!(formik.touched.tenantId && formik.errors.tenantId)}
                                        onBlur={formik.handleBlur}
                                        onChange={onHandleTenant}
                                        disabled={isLoading}
                                    >
                                        {tenantStore.tenants.map(tenant => (
                                            <MenuItem
                                                key={tenant.id}
                                                value={tenant.id}
                                            >
                                                {tenant.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!!(formik.touched.tenantId && formik.errors.tenantId) && <FormHelperText sx={{ color: '#d32f2f' }}>{formik.errors.tenantId}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {formik.errors.submit && (
                                <Typography
                                    color="error"
                                    sx={{ mt: 3 }}
                                    variant="body2"
                                >
                                    {formik.errors.submit}
                                </Typography>
                            )}
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained" type="submit" disabled={isLoading} >
                                    Update {isLoading && <CircularProgress />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export const ConsumerUpdatePage = compose<{}, {}>(observer)(ConsumerUpdatePagePure)
