import React from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type BreadcrumbsProp = {
  name: string;
  link: string;
}

type Props = {
  breadcrumbs: BreadcrumbsProp[]
}

export const BreadcrumbsComponent = (props:Props) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.breadcrumbs.map((breadcrumb, idx) => (
        props.breadcrumbs.length - 1 === idx
          ? <Typography key={idx} color="text.primary">{breadcrumb.name}</Typography>
          : <Box key={idx} color='inherit'>
              <Link key={idx} to={breadcrumb.link} color="inherit" relative='path' style={{ textDecoration: 'none' }}>
                {breadcrumb.name}
              </Link>
          </Box>
      ))}
    </Breadcrumbs>
  )
}
