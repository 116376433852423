import {createBrowserRouter, LoaderFunctionArgs, redirect} from "react-router-dom";
import {DashboardLayout} from "./layouts/dashboard/dashboard.layout";
import {DashboardPage} from "./pages/dashboard/dashboard";
import {ConsumerPage} from "./pages/consumer/consumer";
import {TenantPage} from "./pages/tenant/tenant";
import {UserPage} from "./pages/user/user";
import {LoginLayout} from "./layouts/login/login.layout";
import {LoginPage} from "./pages/login/login";
import {ConsumerCreatePage} from "./pages/consumer/create/consumer.create";
import {TenantCreatePage} from "./pages/tenant/create/tenant.create";
import {UserCreatePage} from "./pages/user/create/user.create";
import {UserUpdatePage} from "./pages/user/update/user.update";
import {ConsumerUpdatePage} from "./pages/consumer/update/consumer.update";
import {TenantUpdatePage} from "./pages/tenant/update/tenant.update";
import {SettingPage} from "./pages/settings/setting.page";
import authStore from "./stores/auth/auth.store";

const protectedLoginLoader = ({ request }: LoaderFunctionArgs) => {
    if (!authStore.isAuthenticated) {
        let params = new URLSearchParams();
        params.set("from", new URL(request.url).pathname);
        return redirect("/login?" + params.toString());
    }
    return null
}

const loginLoader = () => {
    if (authStore.isAuthenticated) {
        return redirect("/");
    }
    return null;
}

export const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        Component: DashboardLayout,
        loader: protectedLoginLoader,
        children: [
            {
                index: true,
                Component: DashboardPage,
            },
            {
                path: 'consumer',
                Component: ConsumerPage,
            },
            {
                path: 'consumer/create',
                Component: ConsumerCreatePage
            },
            {
                path: 'consumer/:id',
                Component: ConsumerUpdatePage
            },
            {
                path: 'tenant',
                Component: TenantPage
            },
            {
                path: 'tenant/create',
                Component: TenantCreatePage
            },
            {
                path: 'tenant/:id',
                Component: TenantUpdatePage
            },
            {
                path: 'user',
                Component: UserPage
            },
            {
                path: 'user/create',
                Component: UserCreatePage
            },
            {
                path: 'user/:id',
                Component: UserUpdatePage
            },
            {
                path: 'settings',
                Component: SettingPage
            }
        ],
    },
    {
        path: "/login",
        Component: LoginLayout,
        loader: loginLoader,
        children: [
            {
                index: true,
                Component: LoginPage
            }
        ]
    }
]);
