import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { compose } from "../../utils/compose/compose";
import {Box, Grid, Typography} from "@mui/material";

const LoginLayoutPure = () => {
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flex: '1 1 auto',
            }}
        >
            <Grid
                container
                sx={{ flex: '1 1 auto' }}
            >
                <Grid
                    xs={12}
                    lg={12}
                    container
                    spacing={0}
                    direction='column'
                    sx={{
                        alignItems: 'center',
                        background: 'radial-gradient(70% 70% at 50% 50%, #ffffff 0%, #c2c2c2 100%)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '100vh'
                    }}
                >
                    <Box sx={{ p: 0 }}>
                        <Typography
                            align="center"
                            color="inherit"
                            sx={{
                                fontSize: '24px',
                                color: '#000000',
                            }}
                            variant="h1"
                        >
                            Welcome to{' '}
                            <Box
                                component="span"
                                sx={{ color: '#15B79E' }}
                            >
                                SDK Admin panel
                            </Box>
                        </Typography>
                    </Box>
                    <Box sx={{ p: 0, width: 550 }}>
                        <Outlet />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export const LoginLayout = compose<{}, {}>(observer)(LoginLayoutPure)