import { apiClient } from "../../utils/api-client/api-client";
import { ConsumerApiNS } from './consumer.api.type';
import { ConsumerModel } from "../../stores/consumer/consumer.model";
import authStore from "../../stores/auth/auth.store";
import { AxiosResponse } from "axios";
import { PaginationModel } from '../../stores/consumer/pagination.model';

export class ConsumerService {
    async getConsumers(params: ConsumerApiNS.GetAllParams) {
        const res = await apiClient.get<
            ConsumerApiNS.GetAllParams,
            AxiosResponse<ConsumerApiNS.PaginatedConsumerResponseDTO>
        >('/api/v1/consumers', {
            params,
            headers: {
                'Authorization': `Bearer ${authStore.token}`
            }
        })

        return {
            meta: new PaginationModel(res.data.meta),
            data: res.data.data.map(consumer => new ConsumerModel(consumer))
        }
    }

    async getConsumerById(id: string){
        const res = await apiClient.get<
            '',
            AxiosResponse<ConsumerApiNS.ConsumerResponseDTO>
        >(
            `/api/v1/consumers/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )

        return new ConsumerModel(res.data);
    }

    async createConsumer(data: ConsumerApiNS.ConsumerCreateDTO) {
        const formData = new FormData();
        Object.entries(data).forEach(([key, val]) => {
            formData.append(key, val);
        });

        const res = await apiClient.post<
            FormData,
            AxiosResponse<ConsumerApiNS.ConsumerResponseDTO>
        >(
            '/api/v1/consumers',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )

        return new ConsumerModel(res.data);
    }

    async updateConsumer(id: string, body: ConsumerApiNS.ConsumerUpdateDTO){
        const formData = new FormData();
        Object.entries(body).forEach(([key, val]) => {
            formData.append(key, val);
        });

        const res = await apiClient.patch<
            FormData,
            AxiosResponse<ConsumerApiNS.ConsumerResponseDTO>
        >(
            `/api/v1/consumers/${id}`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )
        return new ConsumerModel(res.data);
    }

    async deleteConsumer(id: number) {
        await apiClient.delete(
            `/api/v1/consumers/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )
    }

    async sendPushNotification(id: number) {
        await apiClient.post(
            `/api/v1/consumers/${id}/notification`,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        );
    }
}
