import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { DesignStore } from "../../stores/tenant/design.store";
import './phone.css';
import {SdkApplication} from "./sdk-application";
import { WritingStore } from '../../stores/tenant/writing.store';

type Props = {
    designStore: DesignStore,
    writingStore: WritingStore,
    attachedLogo?: string,
}

const PhoneShowcasePure = ({ designStore, writingStore, attachedLogo }: Props) => {
    return (
        <Paper sx={{
            p: 2,
            height: 700,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Grid container >
                <Grid item xs={12} md={12} lg={12}>
                    <Box className='frame-base'>
                        {/*<Box className='frame-base-light' ></Box>*/}
                        <Box className='side-button mute' ></Box>
                        <Box className='side-button volume-up' ></Box>
                        <Box className='side-button volume-down' ></Box>
                        <Box className='side-button hold' ></Box>
                        <Box className='stripe top' ></Box>
                        <Box className='stripe bottom' ></Box>
                        <Box className='port' ></Box>
                        <Box className='layer2' >
                            <Box className='layer2-light' />
                            <Box className='layer2-light light-right' />
                            <Box className='screen' >
                                <Box id='background'>
                                    <SdkApplication
                                      designStore={designStore}
                                      writingStore={writingStore}
                                      attachedLogo={attachedLogo}
                                    />
                                </Box>
                                <Box className="mask">
                                    <Box className="left-pie" />
                                    <Box className="right-pie" />
                                    <Box className="speaker" />
                                    <Box className="camera">
                                        <Box className="lenz left" />
                                        <Box className="lenz right" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const PhoneShowcase = compose<Props, Props>(observer)(PhoneShowcasePure)