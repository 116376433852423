import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    IconButtonProps,
    Slide,
} from '@mui/material';
import { TransitionProps } from "@mui/material/transitions";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
    size?: IconButtonProps["size"],
    iconColor?: "secondary" | "success" | "inherit" | "action" | "warning" | "disabled" | "error" | "primary" | "info",
    idx: number,
    deleteHandler: (idx: number) => void,
    context: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDeleteDialogSlide({ idx, size, iconColor, deleteHandler, context }: Props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApprove = async() => {
        await deleteHandler(idx)
        setOpen(false);
    }

    return (
        <div>
            <IconButton
                size={size}
                onClick={handleClickOpen}
            >
                <DeleteIcon fontSize='small' color={iconColor ?? 'error'} />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Approve?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete: { context }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='error'>Disagree</Button>
                    <Button onClick={handleApprove} color='success'>Agree</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}