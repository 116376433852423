import { AuthApiNS } from "./auth.api.type";
import { apiClient } from "../../utils/api-client/api-client";
import { AxiosResponse } from "axios";

export class AuthService {
    async login(body: AuthApiNS.LoginDTO) {
        const res = await apiClient.post<
            AuthApiNS.LoginResponseDTO,
            AxiosResponse<AuthApiNS.LoginResponseDTO>
        >('/api/v1/auth/login', body)
        return res.data
    }
}
