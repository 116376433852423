import { makeAutoObservable } from "mobx";
import { AuthService } from "../../services/auth/auth.service";
import { AuthApiNS } from "../../services/auth/auth.api.type";
import jwtDecode from "jwt-decode";

export class AuthStore {
    token = localStorage.getItem('token') || ''

    constructor(
        private authService: AuthService = new AuthService(),
    ) {
        makeAutoObservable(this);
    }

    async requestLogin(body: AuthApiNS.LoginDTO) {
        const authModel = await this.authService.login(body);
        this.setToken(authModel.accessToken);
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
        this.token = token
    }

    logout() {
        localStorage.removeItem('token');
        this.token = '';
    }

    get isAuthenticated() {
        if (this.token) {
            const decoded: { exp: number } = jwtDecode(this.token);
            return decoded.exp > Date.now() / 1000;
        }
        return false;
    }
}

const authStore = new AuthStore()

export default authStore