import { makeAutoObservable } from 'mobx';
import { ConsumerModel } from "./consumer.model";
import { ConsumerService } from "../../services/consumer/consumer.service";
import { ConsumerApiNS } from "../../services/consumer/consumer.api.type";
import { PaginationModel } from './pagination.model';

export class ConsumerStore {
    meta: PaginationModel = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 10,
        next: null,
        prev: null
    }
    consumers: ConsumerModel[] = [];

    consumerSearch: string = '';
    isLoading: boolean = false;
    error: string | null = null;

    constructor(
        private consumerService: ConsumerService = new ConsumerService()
    ) {
        makeAutoObservable(this);
    }

    public set search(search: string) {
        this.consumerSearch = search
    }

    *init() {
        yield this.fetchCustomers();
    }

    public async sendPushNotificationToCustomer(id: number) {
        await this.consumerService.sendPushNotification(id);
    }

    public async deleteConsumer(id: number) {
        try {
            this.isLoading = true;
            await this.consumerService.deleteConsumer(id);
            this.consumers = this.consumers.filter(consumer => consumer.id !== id);
        } catch (e) {
            const typedError = e as any as Error
            this.error = typedError?.message
        } finally {
            this.isLoading = false;
        }
    }

    public async createConsumer(body: ConsumerApiNS.ConsumerCreateDTO) {
        const consumer: ConsumerModel = await this.consumerService.createConsumer(body);
        this.consumers.push(consumer);
    }

    public async updateConsumer(id: string, data: ConsumerApiNS.ConsumerUpdateDTO) {
        await this.consumerService.updateConsumer(id, data);
    }

    public async fetchConsumerById(id: string) {
        return this.consumerService.getConsumerById(id);
    }

    public async fetchCustomers() {
        try {
            this.isLoading = true;
            const response =  await this.consumerService.getConsumers({
                search: this.consumerSearch,
                page: this.meta.currentPage,
                perPage: this.meta.perPage,
            });

            this.consumers = response.data;
            this.meta = response.meta;
        } catch (e) {
            const typedError = e as any as Error
            this.error = typedError?.message
        } finally {
            this.isLoading = false;
        }
    }
}

const consumerStore = new ConsumerStore();
export default consumerStore;
