import authStore from "../../stores/auth/auth.store";
import { apiClient } from "../../utils/api-client/api-client";
import { TenantApiNS } from './tenant.api.type';
import { TenantModel } from "../../stores/tenant/tenant.model";
import { AxiosResponse } from "axios";
import { PaginationModel } from '../../stores/consumer/pagination.model';

export class TenantService {
    async getTenants(params: TenantApiNS.GetAllParams) {
        const res = await apiClient.get<
            TenantApiNS.GetAllParams,
            AxiosResponse<TenantApiNS.PaginatedTenantResponseDTO>
        >(
            '/api/v1/tenants',
            {
                params,
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        );

        return {
            meta: new PaginationModel(res.data.meta),
            data: res.data.data.map(data => new TenantModel(data))
        };
    }

    async getTenantById(id: string) {
        const res = await apiClient.get<
            '',
            AxiosResponse<TenantApiNS.TenantPureDTO>
        >(
            `/api/v1/tenants/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        );

        return new TenantModel(res.data);
    }

    async createTenant(body: TenantApiNS.CreateTenantDTO) {
        const formData = new FormData();
        Object.entries(body).forEach(([key, val]) => {
            formData.append(key, val);
        });

        const res = await apiClient.post<
            FormData,
            AxiosResponse<TenantApiNS.TenantPureDTO>
        >('/api/v1/tenants',
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )

        return new TenantModel(res.data);
    }

    async updateTenant(id: string, body: TenantApiNS.UpdateTenantDTO) {
        const formData = new FormData();
        Object.entries(body).forEach(([key, val]) => {
            formData.append(key, val);
        });

        await apiClient.patch<
            FormData,
            AxiosResponse<TenantApiNS.TenantPureDTO>
        >(
            `/api/v1/tenants/${id}`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )
    }

    async deleteTenant(id: number) {
        await apiClient.delete(
            `/api/v1/tenants/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )
    }
}
