import React, {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';


const items = [
    {
        id: 1,
        name: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/'
    },
    {
        id: 2,
        name: 'Users',
        icon: <AdminPanelSettingsIcon />,
        path: '/user'
    },
    {
        id: 3,
        name: 'Tenants',
        icon: <CorporateFareIcon />,
        path: '/tenant'
    },
    {
        id: 4,
        name: 'Consumers',
        icon: <PeopleIcon />,
        path: '/consumer'
    }
]

export const DashboardListItems:FC = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        path: string,
    ) => {
        setSelectedIndex(index);
        navigate(path)
    };

    return (
        <React.Fragment>
            {items.map((item, idx) => {
                return (
                    <ListItemButton
                        key={item.id}
                        selected={selectedIndex === idx}
                        onClick={(event) => handleListItemClick(event, idx, item.path)}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                )
            })}
        </React.Fragment>
    )
}