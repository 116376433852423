import authStore, { AuthStore } from './auth/auth.store';
import consumerStore, { ConsumerStore } from './consumer/consumer.store';
import tenantStore, { TenantStore } from './tenant/tenants.store';
import userStore, { UserStore } from './user/user.store';
import dashboardStore, { DashboardStore } from './dashboard/dashboard.store';
import { createContext, useContext } from 'react';

interface Store {
  authStore: AuthStore,
  consumerStore: ConsumerStore,
  tenantStore: TenantStore,
  userStore: UserStore,
  dashboardStore: DashboardStore,
}

export const store: Store = {
  authStore,
  consumerStore,
  tenantStore,
  userStore,
  dashboardStore
}

export const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);