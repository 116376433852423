import { TenantModel } from "./tenant.model";
import { makeAutoObservable } from 'mobx';
import { TenantService } from "../../services/tenant/tenant.service";
import { TenantApiNS } from "../../services/tenant/tenant.api.type";
import { PaginationModel } from '../consumer/pagination.model';

export class TenantStore {
    meta: PaginationModel = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 10,
        next: null,
        prev: null
    }
    tenants: TenantModel[] = []

    tenantSearch: string = ''
    isLoading: boolean = false;
    error: string | null = null;

    constructor(
        private tenantService: TenantService = new TenantService()
    ) {
        makeAutoObservable(this)
    }

    public set search(search: string) {
        this.tenantSearch = search
    }

    *init() {
        yield this.fetchTenants()
    }

    public async fetchTenants() {
        try {
            this.isLoading = true;
            const response = await this.tenantService.getTenants({
                search: this.tenantSearch,
                page: this.meta.currentPage,
                perPage: this.meta.perPage,
            })

            this.tenants = response.data;
            this.meta = response.meta;
        } catch (e) {
            const typedError = e as any as Error
            this.error = typedError?.message
        } finally {
            this.isLoading = false;
        }
    }

    public async fetchTenantById(id: string) {
        return this.tenantService.getTenantById(id);
    }

    public async postTenant(data: TenantApiNS.CreateTenantDTO) {
        const tenant = await this.tenantService.createTenant(data);
        this.tenants.push(tenant);
    }

    public *updateTenant(id: string, body: TenantApiNS.UpdateTenantDTO) {
        yield this.tenantService.updateTenant(id, body);
    }

    public async deleteTenant(id: number) {
        await this.tenantService.deleteTenant(id);
        this.tenants = this.tenants.filter(tenant => tenant.id !== id);
    }
}

const tenantStore = new TenantStore();
export default tenantStore;
