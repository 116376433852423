import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Grid, InputLabel, Paper, Snackbar, TextField, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserApiNS } from "../../../services/user/user.api.type";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, MessageState } from "../../../components/alert";
import { useStore } from '../../../stores/root.store';
import { BreadcrumbsComponent } from '../../../components/breadcrumbs/breadcrumbs';

const UserUpdatePagePure = () => {
    const { userStore } = useStore();
    const { id } = useParams<"id">();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(100),
            email: Yup
                .string()
                .max(100)
                .required('Email is required'),
            password: Yup
                .string()
                .max(100)
                .required('Password is required')
        }),
        onSubmit: async ({ name, email, password }, helpers) => {
            try {
                const body: UserApiNS.UserUpdateDTO = {
                    name,
                    email,
                    password
                }

                if (id) {
                    await userStore.updateUser(id, body);
                    navigate('/user');
                }
            } catch (e: any) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: e })
                helpers.setSubmitting(false)
            }
        }
    })

    useEffect(() => {
        try {
            setIsLoading(true);
            if (id) {
                userStore.fetchUserById(id).then(
                    ({ name, email, password }) => {
                        formik.setFieldValue('name', name);
                        formik.setFieldValue('email', email);
                        formik.setFieldValue('password', password);
                    }
                );
            }
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            })
        } finally {
            setIsLoading(false);
        }

    }, [id, userStore])

    return (
        <Grid item xs={10} md={8} lg={6}>
            <BreadcrumbsComponent breadcrumbs={[
                {
                    name: 'User',
                    link: '/user'
                },
                {
                    name: `${formik.values.name ?? formik.values.email}`,
                    link: `/user/${id}`
                }
            ]} />
            <Paper sx={{
                p: 2,
                marginTop: 5,
                height: 540,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom > Update User</Typography>
                <Box sx={{ padding: 5 }}>
                    <form
                        noValidate
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Name
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    error={!!(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Email
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        fontWeight: 700
                                    }}
                                >
                                    Password
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isLoading}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained" type="submit" >
                                    Update {isLoading && <CircularProgress />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export const UserUpdatePage = compose<{}, {}>(observer)(UserUpdatePagePure)