import authStore from "../../stores/auth/auth.store";
import { UserApiNS } from './user.api.type';
import { apiClient } from "../../utils/api-client/api-client";
import { UserModel } from "../../stores/user/user.model";
import { AxiosResponse } from "axios";
import { PaginationModel } from '../../stores/consumer/pagination.model';

export class UserService {
    async getUsers(params: UserApiNS.GetAllParams) {
        const res = await apiClient.get<
            UserApiNS.GetAllParams,
            AxiosResponse<UserApiNS.PaginatedUserResponseDTO>
        >(
            '/api/v1/users',
            {
            params,
            headers: {
                'Authorization': `Bearer ${authStore.token}`
            }
        })
        return {
            meta: new PaginationModel(res.data.meta),
            data: res.data.data.map(user => new UserModel(user))
        };
    }

    async getUserById(id: string) {
        const res = await apiClient.get<
            '',
            AxiosResponse<UserApiNS.UserResponseDTO>
        >(
            `/api/v1/users/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )

        return res.data;
    }

    async createUser(body: UserApiNS.UserCreateDTO) {
        const data = JSON.stringify(body)

        const res = await apiClient.post<
            UserApiNS.UserCreateDTO,
            AxiosResponse<UserApiNS.UserResponseDTO>
        >(
            '/api/v1/users',
            data,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        return new UserModel(res.data);
    }

    async updateUser(id: string, body: UserApiNS.UserUpdateDTO) {
        const data = JSON.stringify(body)

        const res = await apiClient.patch<
            UserApiNS.UserUpdateDTO,
            AxiosResponse<UserApiNS.UserResponseDTO>
        >(
            `/api/v1/users/${id}`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        return new UserModel(res.data);
    }

    async deleteUser(id: number) {
        await apiClient.delete(
            `/api/v1/users/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${authStore.token}`
                }
            }
        )
    }
}
