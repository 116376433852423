import React, {FC, useEffect, useState} from "react";
import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import {
    Avatar,
    Box, Button, CircularProgress,
    Grid,
    IconButton,
    Paper, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ConsumerApiNS } from "../../services/consumer/consumer.api.type";
import { useNavigate } from "react-router-dom";
import { AlertDeleteDialogSlide } from "../../components/dialog/alert.delete.dialog.slide";
import { AlertNotifyDialogSlide } from "../../components/dialog/alert.notify.dialog.slide";
import { SearchInputField } from "../../components/input/search.input";
import { Alert, MessageState } from "../../components/alert";
import { useStore } from '../../stores/root.store';

const ConsumerPagePure: FC = () => {
    const { consumerStore } = useStore()
    const navigate = useNavigate();
    const { consumers } = consumerStore
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });
    const handleChangePage = async (event: unknown, newPage: number) => {
        try {
            consumerStore.meta.currentPage = newPage;
            await consumerStore.fetchCustomers();
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            consumerStore.meta.perPage = parseInt(event.target.value, 10);
            consumerStore.meta.currentPage = 0;
            await consumerStore.fetchCustomers();
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        }
    };

    useEffect(() => {
        try {
            console.log('launched')
            setIsLoading(true);
            consumerStore.init()
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            });
        } finally {
            setIsLoading(false)
        }
    }, [consumerStore])

    const getStatusIcon = (status: string) => {
        switch (status) {
            case ConsumerApiNS.ConsumerStatus.APPROVED:
                return <CheckCircleOutlineIcon color='success'/>
            case ConsumerApiNS.ConsumerStatus.PENDING:
                return <PauseCircleOutlineIcon color='warning'/>
            case ConsumerApiNS.ConsumerStatus.BLOCKED:
                return <RemoveCircleOutlineIcon color='error'/>
        }
    }

    const onHandleDelete = async (id: number) => {
        try {
            await consumerStore.deleteConsumer(id);
            setError({
                open: true,
                severity: 'success',
                message: 'Consumer successfully deleted!'
            });
        } catch (e: any) {
            setError({
                open: true,
                severity: 'error',
                message: e?.response?.data?.message || e?.message
            });
        }
    }

    const onHandleNotify = async (id: number) => {
        try {
            await consumerStore.sendPushNotificationToCustomer(id);
            setError({
                open: true,
                severity: 'success',
                message: 'Push notification has been successfully sent!'
            });
        } catch (e: any) {
            setError({
                open: true,
                severity: 'error',
                message: e?.response?.data?.message || e?.message
            });
        }
    }

    const onHandleSearch = async(e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            consumerStore.consumerSearch = e.target.value;
            await consumerStore.fetchCustomers();
        } catch (e: any) {
            setError({
                open: true,
                severity: 'error',
                message: e?.response?.data?.message || e?.message
            });
        }
    }

    if (isLoading) {
        return (<CircularProgress />)
    }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{
                p: 2,
                height: 740,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom >Consumers</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '5px 0'
                }}>
                    <SearchInputField onChange={onHandleSearch} />
                    <Button variant='contained' onClick={() => navigate('create') }>
                        Create consumer
                    </Button>
                </Box>
                <TableContainer>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Tenant</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Date Created</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {consumers.length
                                ? consumers.map((consumer) => (
                                    <TableRow key={consumer.id}>
                                        <TableCell>
                                            <Avatar alt={consumer.email} src={consumer.photo} />
                                        </TableCell>
                                        <TableCell>{consumer.getFullName()}</TableCell>
                                        <TableCell>{consumer.email}</TableCell>
                                        <TableCell>{consumer.tenant?.name ?? '-'}</TableCell>
                                        <TableCell>{getStatusIcon(consumer.status)}</TableCell>
                                        <TableCell>{consumer.createdAt}</TableCell>
                                        <TableCell sx={{ width: '5%' }}>
                                            <AlertNotifyDialogSlide
                                                size='small'
                                                iconColor='info'
                                                idx={consumer.id}
                                                notifyHandler={onHandleNotify}
                                                context={consumer.email} />
                                        </TableCell>
                                        <TableCell style={{ width: '5%' }}>
                                            <IconButton
                                                size='small'
                                                onClick={() => navigate(`${consumer.id}`)}
                                            >
                                                <EditIcon fontSize='small' color='warning' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ width: '5%' }}>
                                            <AlertDeleteDialogSlide
                                                size='small'
                                                iconColor='error'
                                                idx={consumer.id}
                                                deleteHandler={onHandleDelete}
                                                context={consumer.email} />
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRow>
                                    <TableCell align='center' colSpan={8} >
                                        <Typography component='h5' variant='h5' color='blueviolet' gutterBottom >Nothing found</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 35]}
                    component="div"
                    count={consumerStore.meta.total}
                    page={consumerStore.meta.currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={consumerStore.meta.perPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export const ConsumerPage = compose<{}, {}>(observer)(ConsumerPagePure)
