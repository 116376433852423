import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { FC } from "react";
import { useStore } from '../../stores/root.store';

const LoginPagePure: FC = () => {
    const { authStore } = useStore();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const from = params.get("from") || "/";
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('Must be a valid email')
                .max(256)
                .required('Email is required'),
            password: Yup
                .string()
                .max(256)
                .required('Password is required')
        }),
        onSubmit: async ({ email, password}, helpers) => {
            try {
                await authStore.requestLogin({
                    email,
                    password
                });
                await navigate(from);
            } catch (err) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: 'Email or password is incorrect' })
                helpers.setSubmitting(false)
            }
        }
    });


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        error={!!(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={!!(formik.touched.password && formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                    {formik.errors.submit && (
                        <Typography
                            color="error"
                            sx={{ mt: 3 }}
                            variant="body2"
                        >
                            {formik.errors.submit}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </form>
            </Box>
        </Container>
    )
}

export const LoginPage = compose<{}, {}>(observer)(LoginPagePure)